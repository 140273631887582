import React from "react";
import ReactDOM from "react-dom/client";
import * as reactRouterDom from "react-router-dom";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Root from "./routes/Root.jsx";
import Error from "./Error.jsx";
import NewProcedure from "./routes/procedures/NewProcedure.jsx";
import UpdateProcedure from "./routes/procedures/UpdateProcedure.jsx";
import ProcedureList from "./routes/procedures/ProcedureList.jsx";
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev/index.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import { SnackbarProvider } from "notistack";
import ComingSoon from "./routes/ComingSoon.jsx";
import PathologyList from "./routes/pathology/PathologyList.jsx";
import UpdatePathology from "./routes/pathology/UpdatePathology.jsx";

SuperTokens.init({
  appInfo: {
    appName: "CaseLog",
    apiDomain: "https://auth.caselog.io",
    // websiteDomain: "http://localhost:5173",
    websiteDomain: "https://caselog.io",
  },
  recipeList: [
    EmailPassword.init(),
    Session.init({
      sessionTokenBackendDomain: ".caselog.io",
      // sessionTokenFrontendDomain: "http://localhost:5173",
      sessionTokenFrontendDomain: "https://caselog.io",
    }),
  ],
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DevSupport
          ComponentPreviews={ComponentPreviews}
          useInitialHook={useInitial}
        >
          <SnackbarProvider>
            <SuperTokensWrapper>
              <BrowserRouter>
                <Routes>
                  {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
                    EmailPasswordPreBuiltUI,
                  ])}
                  <Route
                    path="/"
                    element={
                      <SessionAuth>
                        <Root />
                      </SessionAuth>
                    }
                    errorElement={<Error />}
                  >
                    <Route
                      path="/new-procedure"
                      element={<NewProcedure />}
                      errorElement={<Error />}
                    />
                    <Route
                      path="/procedures/:logId"
                      element={<UpdateProcedure />}
                      errorElement={<Error />}
                    />
                    <Route
                      path="/procedures"
                      element={<ProcedureList />}
                      errorElement={<Error />}
                    />
                    <Route
                      path="/pathology"
                      element={<PathologyList />}
                      errorElement={<Error />}
                    />
                    <Route
                      path="/pathology/:logId"
                      element={<UpdatePathology />}
                      errorElement={<Error />}
                    />
                    <Route
                      path="/performance"
                      element={<ComingSoon />}
                      errorElement={<Error />}
                    />
                    <Route
                      path="/leaderboards"
                      element={<ComingSoon />}
                      errorElement={<Error />}
                    />
                    <Route
                      path="/"
                      element={<Navigate to="/procedures" />}
                      errorElement={<Error />}
                    />
                  </Route>
                </Routes>
              </BrowserRouter>
            </SuperTokensWrapper>
          </SnackbarProvider>
        </DevSupport>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>,
);

import Container from "@mui/material/Container";
import { JsonForms } from "@jsonforms/react";
import schema from "../../forms/caselog/schema.json";
import uiSchema from "../../forms/caselog/uischema.json";
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Backdrop, Button, Card, CircularProgress, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify-icon/react";
import Typography from "@mui/material/Typography";

const NewProcedure = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    data: {
      procedureDate: new Date().toISOString().split("T")[0],
    },
    errors: [],
  });
  const [validationMode, setValidationMode] = useState("ValidateAndHide");
  const [requestPending, setRequestPending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const theme = createTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            margin: "0.8em 0",
            padding: "0.5em 0.8em",
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: "0.8em 0",
          },
        },
      },
    },
  });

  return (
    <Container maxWidth="md">
      <ThemeProvider theme={theme}>
        <Card>
          <Grid container spacing={1} wrap="nowrap" py={2}>
            <Grid item>
              <Icon icon="ic:outline-info" width="20" />
            </Grid>
            <Grid item>
              <Typography>
                Be honest when filling this out, you are only hurting yourself
                if you lie!
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <JsonForms
          cells={materialCells}
          data={formData.data}
          onChange={({ data, errors }) => {
            setFormData({ data, errors });
          }}
          renderers={materialRenderers}
          schema={schema}
          uischema={uiSchema}
          validationMode={validationMode}
        />
        <Button
          disabled={formData?.errors?.length > 0 || requestPending}
          onClick={() => {
            setRequestPending(true);
            setValidationMode("ValidateAndShow");
            if (!formData?.errors?.length) {
              fetch("https://api.caselog.io/caselog", {
                method: "POST",
                body: JSON.stringify({ data: formData.data }),
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
                .then((response) => {
                  if (response.ok) {
                    enqueueSnackbar("Log submitted successfully!", {
                      autoHideDuration: 3000,
                      variant: "success",
                    });
                    navigate("/procedures");
                  } else {
                    enqueueSnackbar("Error occurred while saving log.", {
                      autoHideDuration: 3000,
                      variant: "error",
                    });
                  }
                })
                .catch(() => {
                  enqueueSnackbar("Error occurred while saving log.", {
                    autoHideDuration: 3000,
                    variant: "error",
                  });
                })
                .finally(() => setRequestPending(false));
            }
          }}
          fullWidth
          variant="contained"
          size="large"
        >
          SUBMIT
        </Button>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={requestPending}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    </Container>
  );
};

export default NewProcedure;

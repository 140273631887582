import * as React from "react";
import { Outlet } from "react-router";
import { useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Icon } from "@iconify-icon/react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { signOut } from "supertokens-auth-react/recipe/session";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    backgroundColor: "#ededed",
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            color="inherit"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Case Log
          </Typography>
          <Button
            color="inherit"
            variant="text"
            onClick={async () => {
              await signOut();
              navigate("/auth");
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader title="Menu">
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key="Procedures" disablePadding>
            <ListItemButton component={Link} to="/procedures">
              <ListItemIcon>
                <Icon icon="la:procedures" width="40" />
              </ListItemIcon>
              <ListItemText
                primary="Procedures"
                primaryTypographyProps={{ fontSize: 20 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="Pathology" disablePadding>
            <ListItemButton component={Link} to="/pathology">
              <ListItemIcon>
                <Icon icon="medical-icon:i-pathology" width="40" />
              </ListItemIcon>
              <ListItemText
                primary="Pathology Followups"
                primaryTypographyProps={{ fontSize: 20 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="My Performance" disablePadding>
            <ListItemButton component={Link} to="/performance">
              <ListItemIcon>
                <Icon icon="codicon:graph-line" width="40" />
              </ListItemIcon>
              <ListItemText
                primary="My Performance"
                primaryTypographyProps={{ fontSize: 20 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="Leaderboards" disablePadding>
            <ListItemButton component={Link} to="/leaderboards">
              <ListItemIcon>
                <Icon icon="iconoir:leaderboard-star" width="40" />
              </ListItemIcon>
              <ListItemText
                primary="Leaderboards"
                primaryTypographyProps={{ fontSize: 20 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem key="Contact Us" disablePadding>
            <ListItemButton component={Link} to="/contact-us">
              <ListItemIcon>
                <Icon icon="grommet-icons:contact" width="40" />
              </ListItemIcon>
              <ListItemText
                primary="Contact Us"
                primaryTypographyProps={{ fontSize: 20 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import {
  Backdrop,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Container,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { orderBy } from "lodash";
import { Icon } from "@iconify-icon/react";

const PathologyList = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery("(min-width:600px)");
  const [caseLogs, setCaseLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch("https://api.caselog.io/caselogs")
      .then((response) => response.json())
      .then((response) =>
        setCaseLogs(
          orderBy(
            response?.data?.caseLogListItems?.filter(
              (item) =>
                item.collectedPathologySpecimens === "Yes" &&
                !item.pathologyComplete,
            ) ?? [],
            ["procedureDate"],
            ["desc"],
          ),
        ),
      )
      .catch(() => setCaseLogs([]))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container maxWidth={false}>
      {!loading && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              color="secondary"
              component={Link}
              size="large"
              to="/new-procedure"
              variant="contained"
            >
              Log New Procedure
            </Button>
          </Grid>
          <Grid item xs={12}>
            <MUIDataTable
              title="Pathology Followups"
              columns={[
                {
                  name: "procedureDate",
                  label: "Procedure Date",
                  options: {
                    customBodyRenderLite: (dataIndex) => {
                      const log = caseLogs[dataIndex];
                      return new Date(log.procedureDate).toLocaleDateString();
                    },
                  },
                },
                {
                  name: "proceduresPerformed",
                  label: "Procedure Type",
                  options: {
                    customBodyRender: (value) => value?.join(", ") ?? "",
                  },
                },
                { name: "patientMrn", label: "Patient MRN" },
                {
                  name: "hospital",
                  label: "Hospital",
                },
                {
                  name: "attendingSupervising",
                  label: "Attending Supervising",
                  options: {
                    display: false,
                  },
                },
                {
                  name: "collectedPathologySpecimens",
                  label: "Pathology",
                  options: {
                    customBodyRenderLite: (dataIndex) => {
                      const log = caseLogs[dataIndex];
                      return log.pathologyComplete ? (
                        <Chip
                          color="success"
                          icon={<Icon icon="carbon:task-complete" width={20} />}
                          label="COMPLETE"
                        />
                      ) : (
                        <Chip
                          color="primary"
                          icon={
                            <Icon icon="ic:sharp-pending-actions" width={20} />
                          }
                          label="PENDING"
                        />
                      );
                    },
                  },
                },
                {
                  name: "action",
                  label: "Actions",
                  options: {
                    customBodyRenderLite: (dataIndex) => {
                      const caseLog = caseLogs[dataIndex];
                      const buttons = [
                        <Button
                          color="primary"
                          component={Link}
                          key="edit-procedure"
                          startIcon={<Icon icon="la:procedures" width={25} />}
                          to={`/procedures/${caseLog?.caseLogId}`}
                        >
                          Procedure
                        </Button>,
                      ];

                      if (caseLog.collectedPathologySpecimens === "Yes") {
                        buttons.push(
                          <Button
                            color="secondary"
                            component={Link}
                            key="pathology"
                            startIcon={
                              <Icon
                                icon="medical-icon:i-pathology"
                                width={23}
                              />
                            }
                            to={`/pathology/${caseLog?.caseLogId}`}
                          >
                            Pathology
                          </Button>,
                        );
                      }

                      return (
                        <ButtonGroup
                          variant="outlined"
                          aria-label="Actions"
                          orientation={largeScreen ? "horizontal" : "vertical"}
                        >
                          {buttons}
                        </ButtonGroup>
                      );
                    },
                    print: false,
                    filter: false,
                    download: false,
                  },
                },
              ]}
              data={caseLogs}
              options={{
                selectableRowsHideCheckboxes: true,
                setRowProps: (row) => {
                  if (row[5] === "Yes" && !row[6]) {
                    return {
                      style: { background: theme.palette.warning.main },
                    };
                  }
                },
              }}
            />
          </Grid>
        </Grid>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default PathologyList;

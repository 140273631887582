import Container from "@mui/material/Container";
import { JsonForms } from "@jsonforms/react";
import schema from "../../forms/caselog/schema.json";
import uiSchema from "../../forms/caselog/uischema.json";
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Grid,
  Snackbar,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { Icon } from "@iconify-icon/react";
import Typography from "@mui/material/Typography";

const UpdateProcedure = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    data: {},
    errors: [],
  });
  const [validationMode, setValidationMode] = useState("ValidateAndHide");
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("Log saved successfully!");
  const [requestPending, setRequestPending] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { logId } = useParams();

  const theme = createTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            margin: "0.8em 0",
            padding: "0.5em 0.8em",
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: "0.8em 0",
          },
        },
      },
    },
  });

  useEffect(() => {
    setError(false);
    setLoading(true);
    fetch(`https://api.caselog.io/caselog/${logId}`)
      .then((response) => response.json())
      .then((response) =>
        setFormData({ data: response?.data ?? {}, errors: [] }),
      )
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [logId]);

  return (
    <Container maxWidth="md">
      {error ? (
        <>Error</>
      ) : (
        <ThemeProvider theme={theme}>
          <Card>
            <Grid container spacing={1} wrap="nowrap">
              <Grid item>
                <Icon icon="ic:outline-info" width="20" />
              </Grid>
              <Grid item>
                <Typography>
                  Be honest when filling this out, you are only hurting yourself
                  if you lie!
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <JsonForms
            cells={materialCells}
            data={formData.data}
            onChange={({ data, errors }) => {
              setFormData({ data, errors });
            }}
            renderers={materialRenderers}
            schema={schema}
            uischema={uiSchema}
            validationMode={validationMode}
          />
          <Button
            disabled={formData?.errors?.length > 0 || requestPending}
            onClick={() => {
              setRequestPending(true);
              setValidationMode("ValidateAndShow");
              if (!formData?.errors?.length) {
                fetch(`https://api.caselog.io/caselog/${logId}`, {
                  method: "PUT",
                  body: JSON.stringify({ data: formData.data }),
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                })
                  .then((response) => {
                    if (response.ok) {
                      setSnackMessage("Log submitted successfully!");
                      setSnackOpen(true);
                      navigate("/procedures");
                    } else {
                      setSnackMessage("Something went wrong!");
                      setSnackOpen(true);
                    }
                  })
                  .catch(() => {
                    setSnackMessage("Something went wrong!");
                    setSnackOpen(true);
                  })
                  .finally(() => setRequestPending(false));
              }
            }}
            fullWidth
            variant="contained"
            size="large"
          >
            SUBMIT
          </Button>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            autoHideDuration={5000}
            message={snackMessage}
            onClose={() => setSnackOpen(false)}
            open={snackOpen}
          />
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={requestPending || loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </ThemeProvider>
      )}
    </Container>
  );
};

export default UpdateProcedure;

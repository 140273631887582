import Container from "@mui/material/Container";
import { Card, CardContent, Grid } from "@mui/material";
import { Icon } from "@iconify-icon/react";
import Typography from "@mui/material/Typography";

const ComingSoon = () => (
  <Container maxWidth="sm">
    <Card>
      <CardContent sx={{ p: 4 }}>
        <Grid container alignItems="center" spacing={2} wrap="nowrap">
          <Grid item>
            <Icon icon="ion:rocket-outline" width="100" />
          </Grid>
          <Grid item>
            <Typography variant="h2">Coming Soon!</Typography>
            <Typography variant="body1">
              Maybe a description of future features goes here.
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Container>
);

export default ComingSoon;
